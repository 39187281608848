var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-tabs',{attrs:{"content-class":"mt-3"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"Redacción"}},[_c('div',{staticClass:"top-row"},[_c('div',{staticClass:"competence-type"},[(_vm.competenceTypes.length > 0)?_c('b-form-group',{staticClass:"m-0",attrs:{"label-cols":"6","label-cols-sm":"6","label-cols-lg":"6","label":_vm.$getVisibleNames(
                'mesh.competencetype',
                false,
                'Tipo de Competencia'
              ),"label-for":"input-type"}},[_c('b-form-select',{attrs:{"id":"input-type","options":_vm.competenceTypes,"value-field":"id","text-field":"name","state":_vm.validateCompetenceState('type'),"disabled":!_vm.can_edit_type && !isNaN(_vm.type_id),"size":"sm","aria-describedby":"input-type-feedback"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Seleccione una de las opciones")])]},proxy:true}],null,false,2144328299),model:{value:(_vm.$v.competence.type.$model),callback:function ($$v) {_vm.$set(_vm.$v.competence.type, "$model", $$v)},expression:"$v.competence.type.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-type-feedback"}},[_vm._v("Este campo es requerido.")])],1):_vm._e(),(_vm.egressProfileCycleSort.length > 0 && _vm.can_edit_profile_cycle)?_c('b-form-group',{staticClass:"m-0",attrs:{"label-cols":"6","label-cols-sm":"6","label-cols-lg":"6","label":_vm.$getVisibleNames('mesh.cycle', false, 'Ciclo Formativo'),"label-for":"input-type"}},[_c('b-form-select',{attrs:{"id":"input-type","options":_vm.egressProfileCycleSort,"value-field":"id","text-field":"name","size":"sm","aria-describedby":"input-type-feedback"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Seleccione una de las opciones")])]},proxy:true}],null,false,2144328299),model:{value:(_vm.profile_competence.cycle),callback:function ($$v) {_vm.$set(_vm.profile_competence, "cycle", $$v)},expression:"profile_competence.cycle"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-type-feedback"}},[_vm._v("Este campo es requerido.")])],1):_vm._e(),(_vm.egress_profile_view)?_c('b-form-group',{staticClass:"m-0",attrs:{"label-cols":"6","label-cols-sm":"6","label-cols-lg":"6","label":"Orden","label-for":"input-order"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.v-secondary.noninteractive",value:(
                `${
                  !_vm.orderState
                    ? String(
                        'Este perfil ya cuenta con una ' +
                          _vm.$getVisibleNames(
                            'mesh.competence',
                            false,
                            'Competencia'
                          ) +
                          ' en este orden.'
                      )
                    : ''
                }`
              ),expression:"\n                `${\n                  !orderState\n                    ? String(\n                        'Este perfil ya cuenta con una ' +\n                          $getVisibleNames(\n                            'mesh.competence',\n                            false,\n                            'Competencia'\n                          ) +\n                          ' en este orden.'\n                      )\n                    : ''\n                }`\n              ",modifiers:{"left":true,"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 py-0",attrs:{"type":"number","min":"0","size":"sm","disabled":!_vm.can_edit_order,"state":_vm.orderState},model:{value:(_vm.profile_competence.order),callback:function ($$v) {_vm.$set(_vm.profile_competence, "order", $$v)},expression:"profile_competence.order"}})],1):_vm._e(),(
              _vm.egress_profile_view &&
              _vm.institution &&
              _vm.institution.show_competence_sub_order &&
              (_vm.showCompetenceSubOrder || _vm.profile_competence.sub_order > 0)
            )?_c('b-form-group',{staticClass:"m-0",attrs:{"label-cols":"6","label-cols-sm":"6","label-cols-lg":"6","label":"Sub Orden","label-for":"input-order"}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.left.v-secondary.noninteractive",value:(
                `${
                  !_vm.orderState
                    ? String(
                        'Este perfil ya cuenta con una ' +
                          _vm.$getVisibleNames(
                            'mesh.competence',
                            false,
                            'Competencia'
                          ) +
                          ' en este orden.'
                      )
                    : ''
                }`
              ),expression:"\n                `${\n                  !orderState\n                    ? String(\n                        'Este perfil ya cuenta con una ' +\n                          $getVisibleNames(\n                            'mesh.competence',\n                            false,\n                            'Competencia'\n                          ) +\n                          ' en este orden.'\n                      )\n                    : ''\n                }`\n              ",modifiers:{"left":true,"v-secondary":true,"noninteractive":true}}],staticClass:"m-0 py-0",attrs:{"type":"number","min":"1","size":"sm","disabled":!_vm.can_edit_order,"state":_vm.orderState},model:{value:(_vm.profile_competence.sub_order),callback:function ($$v) {_vm.$set(_vm.profile_competence, "sub_order", $$v)},expression:"profile_competence.sub_order"}})],1):_vm._e()],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.hover.top",value:(
            'Ingresar oración directamente o utilizar el modo de “creación“'
          ),expression:"\n            'Ingresar oración directamente o utilizar el modo de “creación“'\n          ",modifiers:{"v-secondary":true,"hover":true,"top":true}}],staticClass:"toggle-container"},[_c('span',{class:{ 'font-weight-bold': !_vm.checked }},[_vm._v("Insertar")]),_c('b-button',{staticClass:"check-type",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.changeChecked()}}},[_c('b-icon',{staticClass:"icon-check",class:{ 'icon-check-active': _vm.checked },attrs:{"icon":"circle-fill","scale":"0.7"}})],1),_c('span',{class:{ 'font-weight-bold': _vm.checked }},[_vm._v("Crear")])],1)]),_c('CompetenceSentenceForm',{key:_vm.keyRender,attrs:{"verb":_vm.competence.verb,"first_content":_vm.competence.action,"second_content":_vm.competence.content,"third_content":_vm.competence.condition,"fourth_content":_vm.competence.context,"full_sentence":_vm.competence.full_sentence,"checked":_vm.checked},on:{"update":_vm.slotUpdateSentence}}),(
          ((_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
            _vm.$debug_change_duoc) &&
          _vm.egress_profile_view
        )?_c('div',[_c('b-form-group',{staticClass:"m-0 mb-2",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-capacity"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.capacity", true, "Capacidades"))+" "),(_vm.career && _vm.egressProfile && !_vm.egressProfile.is_closed)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_capacity'),expression:"'mesh.add_capacity'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                  `Agregar ${_vm.$getVisibleNames(
                    'mesh.capacity',
                    true,
                    'Capacidades'
                  )}`
                ),expression:"\n                  `Agregar ${$getVisibleNames(\n                    'mesh.capacity',\n                    true,\n                    'Capacidades'\n                  )}`\n                ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-add mt-0 mb-auto mr-0 ml-auto",on:{"click":_vm.openModalCapacity}},[_c('b-icon',{attrs:{"font-scale":"1","icon":"plus-square"}})],1):_vm._e()])]},proxy:true}],null,false,3936924722)},[(_vm.capacitiesFilter.length > 0)?_c('b-form-select',{attrs:{"id":"input-capacity","options":_vm.capacitiesFilter,"value-field":"id","text-field":"name","multiple":"","select-size":_vm.capacitiesFilter.length <= 5 ? _vm.capacitiesFilter.length : 5,"size":"sm"},on:{"change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" --- Limpiar selección ---")])]},proxy:true}],null,false,703762647),model:{value:(_vm.profile_competence.capacities),callback:function ($$v) {_vm.$set(_vm.profile_competence, "capacities", $$v)},expression:"profile_competence.capacities"}}):[_c('div',{staticClass:"mt-1"},[_vm._v(" No cuenta con "+_vm._s(_vm.$getVisibleNames("mesh.capacity", true, "Capacidades"))+" creadas ")])]],2)],1):_vm._e(),(!isNaN(_vm.competence.id))?_c('b-form-group',{staticClass:"p-0 m-0 mt-2 mb-2",attrs:{"label-for":"input-modification-date","label-cols":"0","label-cols-sm":"0"}},[_c('ModificationDate',{attrs:{"Information":_vm.competence}})],1):_vm._e()],1),(!_vm.egress_profile_view)?_c('b-tab',{attrs:{"title":`Asociar ${_vm.$getVisibleNames(
        'mesh.competence',
        false,
        'Competencia'
      )}`}},[_c('div',{staticClass:"ml-2"},[_c('EgressProfileCompetence',{attrs:{"Competence":_vm.competence,"egress_profile_id":_vm.egress_profile_id,"selected_ids":_vm.selected_ids,"cycle_id":_vm.cycle_id,"order":_vm.order,"can_edit_order":_vm.can_edit_order,"can_edit_cycle":_vm.can_edit_cycle},on:{"updated":_vm.slotUpdatedProfileCompetences}})],1)]):_vm._e(),(!isNaN(_vm.competence.id))?_c('b-tab',{attrs:{"title":`Asociar ${_vm.$getVisibleNames(
        'mesh.competencedescriptor',
        false,
        'descriptor de la Competencia'
      )}`}},[_c('CompetenceDescriptors',{attrs:{"competence_id":_vm.competence.id,"allows_crud":_vm.allows_crud},on:{"reset_competence":() => this.$emit('reset_competence'),"change_middle_descriptors":(value) => this.$emit('change_middle_descriptors', value),"reset_competence_table":() => this.$emit('reset_competence_table')}})],1):_vm._e()],1),_c('b-modal',{attrs:{"id":`new-capacity-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames('mesh.capacity', true, 'Capacidades')}`,"size":"md"}},[(_vm.career)?_c('CapacityForm',{attrs:{"faculty_id":_vm.career.faculty},on:{"created":_vm.slotCreatedCapacity}}):_vm._e()],1),_c('div',{staticClass:"row"},[(_vm.show_delete_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(!isNaN(_vm.profile_competence.id))?_c('b-button',{staticClass:"mr-1",style:({
          cursor: !isNaN(_vm.competence.id) ? 'pointer' : 'not-allowed',
        }),attrs:{"variant":"danger","size":"sm","disabled":isNaN(_vm.competence.id)},on:{"click":function($event){return _vm.askDeleteProfileCompetence(_vm.profile_competence.id)}}},[_vm._v("Eliminar")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[(!isNaN(_vm.competence.id))?[(
            (_vm.institution && _vm.institution.show_base_ra) || _vm.$debug_change_duoc
          )?_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-base-ra`)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("teaching.basera", false, "RA Base"))+" "),(_vm.raBasesFiltered)?_c('b-badge',{staticClass:"ra-bases-badge",attrs:{"variant":"light"}},[_vm._v(_vm._s(_vm.raBasesFiltered.length))]):_vm._e()],1):_vm._e(),_c('b-modal',{attrs:{"id":`modal-base-ra`,"hide-footer":"","title":_vm.$getVisibleNames('teaching.basera', false, 'RA Base'),"size":"lg"}},[_c('RaBase',{attrs:{"competence_id":_vm.competence.id}})],1)]:_vm._e(),_c('b-button',{staticClass:"mr-1",style:({
          cursor: _vm.valid_competence ? 'pointer' : 'not-allowed',
        }),attrs:{"size":"sm","disabled":!_vm.valid_competence,"variant":_vm.valid_competence ? 'secondary' : 'light'},on:{"click":_vm.saveCompetence}},[_vm._v("Guardar")])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }